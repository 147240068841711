import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { getCodeLabel } from '@core/utils/filter'

export default function mainTableItemUseList(config) {
  // Use toast
  const toast = useToast()

  const refListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    // { key: 'id', label: 'ID', sortable: true },
    // { key: 'order_id', label: '采购订单ID', sortable: true },
    // { key: 'order_no', label: '采购订单编号', sortable: true },
    // { key: 'product_id', label: '商品ID', sortable: true },
    // { key: 'category_id', label: '分类ID', sortable: true },
    // { key: 'category_name', label: '分类名称', sortable: true },
    // { key: 'brand_id', label: '品牌ID', sortable: true },
    // { key: 'brand_name', label: '品牌名称', sortable: true },
    { key: 'name', label: '名称'},
    { key: 'purchase_team', label: '销售权'},
    { key: 'qty', label: '数量'},
    { key: 'product_cost_tax', label: '含税成本'},
    /*{ key: 'product_cost_tax_modify', label: '调整单价'},
    { key: 'product_cost_tax_modifyed', label: '调整后单价'},*/
    { key: 'product_cost_tax_premodify', label: '调整前单价'},
    // { key: 'product_cost_subtotal', label: '小计'},
    // { key: 'product_cost', label: '成本'},
    // { key: 'product_cost_tax_subtotal', label: '税后小计'},
    { key: 'tax_rate', label: '税率' ,formatter:(value) => getCodeLabel("tax_rate",value)},
    { key: 'expected_gross_profit_rate', label: '预计毛利率/%'},
    // { key: 'tax_subtotal', label: '税额'},
    // { key: 'extra_cost', label: '附加费'},
    // { key: 'extra_cost_subtotal', label: '附加费小计'},
    // { key: 'cost', label: '金额'},
    { key: 'sales_period', label: '销售周期(天)'},
    { key: 'subtotal', label: '价税合计'},
    { key: 'refund_amount', label: '退款'},
    { key: 'refund_qty', label: '退货数量'},
    // { key: 'invoicing_qty', label: '开票数量'},
    // { key: 'invoicing_amount', label: '开票金额'},
    { key: 'waitCount', label: '待入数量',formatter:(value,key,item) => item.ext.waitCount},
    { key: 'delivery_qty', label: '到货数量'},
    { key: 'delivery_amount', label: '到货金额'},
    { key: 'is_gift', label: '是否赠品',formatter:(value) => getCodeLabel("yesno",value)},
    /*{ key: 'state', label: '状态', sortable: true },
    { key: 'add_time', label: '添加时间', sortable: true },
    { key: 'modify_time', label: '修改时间', sortable: true },*/
    { key: 'actions', label: '操作' },
  ]
  if (config.isWatcher){
      tableColumns.splice(tableColumns.length -1,1)
    }
  const start = ref(1)
  const limit = ref(1000)
  const limitOptions = [10, 25, 50, 100]
  const listTotals = ref(0)
  const searchKey = ref('')
  const orderBy = ref('item_id')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: limit.value * (start.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start.value - 1) + localItemsCount,
      of: listTotals.value,
    }
  })

  const refetchData = () => {
	  refListTable.value.refresh()
  }

  watch([start, limit, searchKey], () => {
    refetchData()
  })

  const searchList = (ctx, callback) => {
    store
      .dispatch('purchaseorderitem/itemList', {
        start: start.value,
        limit: limit.value,
        order_by: orderBy.value,
        order_desc: isSortDirDesc.value === true ? 'desc':'',
        order_id : config.order_id,
      })
      .then(response => {
        const data = response.data.data
        const list = data.ext.list
        listTotals.value = data.ext.totals
        callback(list)
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: '采购单明细列表获取错误',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  return {
    searchList,
    tableColumns,
    limit,
    start,
    listTotals: listTotals,
    dataMeta,
    limitOptions,
    searchKey,
    orderBy,
    isSortDirDesc,
    refListTable,

    refetchData,
  }
}
