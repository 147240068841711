import { computed, onMounted, reactive, ref } from '@vue/composition-api'
import store from '@/store'
import { add, subtract } from '@/@core/utils/math/utils'
import { useToast } from 'vue-toastification/composition'

export default function financepaymentitemUseList(config, emit) {

  const toast = useToast()

  const getList = async () => {
    if (config.f_payment_id) {
      state.isBusy = true
      const res = await store.dispatch('financepaymentitem/list', { f_payment_id: config.f_payment_id })
      state.isBusy = false
      if (res['data'].code === 0) {
        state.items = res['data'].data.ext.list
      } else {
        toast.error('明细列表获取错误')
      }
    }
  }

  const curKey = ref('')

  const updateReadonly = (readonly) => {
    const value = { ...filterItem.value }
    if (value) {
      value.readonly = readonly
      value.detailConfig = getDetailConfig(value.id, readonly)
    }
    updateEditableData(value)
  }

  const updateEditableData = (value)=>{
    const obj = {}
    obj[curKey.value] = value
    state.editableData = Object.assign({}, state.editableData, obj)
  }

  const getDetailConfig = (id,disabled) => {
    return {
      our_bank_account: {
        type: 'input',
        attrs: {
          id: `our_bank_account_${id}`,
          label: '我方银行账号',
          readonly: false,
          disabled:disabled,
        },
      },
      our_bank_name: {
        type: 'input',
        attrs: {
          id: `our_bank_name_${id}`,
          label: '我方账户名称',
          readonly: false,
          disabled:disabled,
        },
      },
      our_opening_bank: {
        type: 'input',
        attrs: {
          id: `our_opening_bank_${id}`,
          label: '我方开户行',
          readonly: false,
          disabled:disabled,
        },
      },
      inner_account: {
        type: 'input',
        attrs: {
          id: `inner_account_${id}`,
          label: '内部账号',
          readonly: false,
          disabled:disabled,
        },
      },
      inner_account_name: {
        type: 'input',
        attrs: {
          id: `inner_account_name_${id}`,
          label: '内部账号名称',
          readonly: false,
          disabled:disabled,
        },
      },
      other_bank_account: {
        type: 'input',
        attrs: {
          id: `other_bank_account_${id}`,
          label: '对方银行账号',
          readonly: false,
          disabled:disabled,
        },
      },
      other_bank_name: {
        type: 'input',
        attrs: {
          id: `other_bank_name_${id}`,
          label: '对方账户名称',
          readonly: false,
          disabled:disabled,
        },
      },
      opening_bank_address: {
        type: 'input',
        attrs: {
          id: `opening_bank_address_${id}`,
          label: '开户行地址',
          readonly: false,
          disabled:disabled,
        },
      },
    }
  }

  const filterItem = computed(() => {
    return state.items.filter(item => item.id === curKey.value)[0]
  })

  const validateRow = async function () {
    let refs = state.observer.refs
    let validArr = []
    for (const key in refs) {
      if (refs.hasOwnProperty(key) && key.includes(curKey.value)) {
        let validate = await refs[key].validate()
        if (!validate['valid']) {
          validArr.push(key)
        }
      }
    }
    return validArr.length === 0
  }

  const updateAmount = ()=>{
    const payable_amount = state.filterItems.reduce((acc, cur) => add(acc, cur.payable_amount), 0)
    const paid_amount = state.filterItems.reduce((acc, cur) => add(acc, cur.paid_amount), 0)
    emit('updateAmount', payable_amount, paid_amount)
  }
  const methods = {
    showOrderModal(){
      state.orderModal.show()
    },
    onRowDblClicked(item){
      /*state.financePayment['order_id'] = item.order_id
      state.financePayment['order_no'] = item.order_no*/
      state.orderModal.hide();
      const curItem = state.editableData[curKey.value]
      curItem['order_id'] = item.order_id
      curItem['order_no'] = item.order_no
      updateEditableData(curItem)
    },
    editable(key) {
      return state.editableData[key] && !state.editableData[key].readonly
    },
    toggleDetail(row) {
      updateReadonly(true)
      row.toggleDetails()
    },
    onRowHovered(item) {
      curKey.value = item.id
    },
    updateAfterDiscount() {
      const curItem = state.editableData[curKey.value]
      curItem['after_discount_amount'] = subtract(curItem['payable_amount'], curItem['cash_discount'])
      curItem['paid_amount'] = add(curItem['after_discount_amount'], curItem['service_charge'])
    },
    updatePaid() {
      const curItem = state.editableData[curKey.value]
      curItem['paid_amount'] = add(curItem['after_discount_amount'], curItem['service_charge'])
    },
    cancel() {
      updateReadonly(true)
      filterItem.value._showDetails = false
    },
    edit() {
      updateReadonly(false)
      filterItem.value._showDetails = true

    },
    del() {
      this.cancel()
      filterItem.value.state = 0
      updateAmount()
    },
    async save() {
      const success = await validateRow()
      if (!success) {
        toast.error('请正确填写必填字段')
        return
      }
      filterItem.value._showDetails = false
      Object.assign(filterItem.value, state.editableData[curKey.value])
      updateAmount()
      this.cancel()
    },
    refresh() {
      state.refListTable.refresh()
    },
    handleAdd() {
      const newData = {
        id: 'new' + (state.items.length - 1),
        f_settlement_method: 'JSFS04_SYS',
        payment_purpose: 'SFKYT08_SYS',
        prepay_project_type: undefined,
        payable_amount: 0,
        cash_discount: 0,
        after_discount_amount: 0,
        long_short: 0,
        service_charge: 0,
        paid_amount: 0,
        state: 1,
        order_id:undefined,
        order_no:undefined,
        remark: undefined,
      }
      state.items.push(newData)
      curKey.value = newData.id
      this.edit()
    }
  }

  const state = reactive({

    orderModal:undefined,

    inputCol: [
      {
        key: 'cell(payable_amount)',
        rules: 'required|nonnegative',
        on: {
          input: methods.updateAfterDiscount
        }
      },
      {
        key: 'cell(cash_discount)',
        rules: 'nonnegative',
        on: {
          input: methods.updateAfterDiscount
        }
      },
      {
        key: 'cell(service_charge)',
        rules: 'nonnegative',
        on: {
          input: methods.updatePaid
        }
      },
      {
        key: 'cell(after_discount_amount)',
        rules: 'nonnegative',
        readonly: true
      },
      {
        key: 'cell(paid_amount)',
        rules: 'nonnegative',
        readonly: true
      },
      {
        key: 'cell(order_no)',
        readonly: true,
        on: {
          click: () => methods.showOrderModal('purchase_order')
        }
      },
    ],
    tableColumns: [
      {
        key: '#',
        label: '序号'
      },
      {
        key: 'f_settlement_method',
        label: '结算方式'
      },
      {
        key: 'payment_purpose',
        label: '付款用途'
      },
      // { key: 'prepay_project_type', label: '预付项目类型' },
      {
        key: 'payable_amount',
        label: '应付金额'
      },
      {
        key: 'cash_discount',
        label: '现金折扣'
      },
      {
        key: 'after_discount_amount',
        label: '折后金额'
      },
      // { key: 'long_short', label: '长短款' },
      {
        key: 'service_charge',
        label: '手续费'
      },
      {
        key: 'paid_amount',
        label: '实付金额'
      },
      /*{ key: 'order_id', label: '采购订单ID' },*/
      { key: 'order_no', label: '采购订单' },
      /*{ key: 'our_bank_account', label: '我方银行账号' },
      { key: 'our_bank_name', label: '我方账户名称' },
      { key: 'our_opening_bank', label: '我方开户行' },
      { key: 'inner_account', label: '内部账号' },
      { key: 'inner_account_name', label: '内部账号名称' },
      { key: 'other_bank_account', label: '对方银行账号' },
      { key: 'other_bank_name', label: '对方账户名称' },
      { key: 'opening_bank_address', label: '开户行地址' },*/
      // { key: 'remark', label: '备注' },
      // { key: 'state', label: '状态' },
      /*{ key: 'create_time', label: '添加时间' },
      { key: 'creator', label: '添加人ID' },
      { key: 'update_time', label: '更新时间' },
      { key: 'updator', label: '更新人ID' },*/
      {
        key: 'actions',
        label: '操作'
      },
    ],
    items: [],
    isBusy: false,
    refListTable: undefined,

    editableData: reactive({}),

    observer: undefined,
    filterItems: computed(() => state.items.filter(item => item.state === 1)),
  })

  onMounted(async () => {
    await getList()
  })

  return {
    methods,
    state,
  }
}
