<template>

  <div>

    <!-- Table Container Card -->
    <b-card
        no-body
        class="mb-0 list-table"
    >
        <b-table
            sticky-header
            noCollapse
            ref="refListTable"
            class="position-relative"
            :items="searchList"
            responsive
            :fields="tableColumns"
            primary-key="id"
            :sort-by.sync="orderBy"
            show-empty
            empty-text="未找到记录"
            :sort-desc.sync="isSortDirDesc"
        >

          <template #cell(name)="data">
            [#{{ data.item.product_id }}]{{ data.item.name }}
            <feather-icon
                icon="TwitchIcon"
                size="21"
                variant="success"
                :id="`item-row-${data.item.id}`"
                v-b-tooltip.hover.html="
              '<div class=text-left>'+
              '69码：'+data.item.ext.product.code+
              '<br>'+'箱规：'+data.item.ext.product.box_quantity+
              '</div>'"
            />
          </template>

          <template #cell(purchase_team)="data">
            {{ getCodeLabel("purchase_team",data.item.purchase_team_id) }}
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-dropdown
                variant="link"
                no-caret
            >
              <template #button-content>
                <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                />
              </template>
              <b-dropdown-item
                               @click="refund(data.item)"
              >
                <feather-icon icon="FileTextIcon"/>
                <span class="align-middle ml-50">退款</span>
              </b-dropdown-item>
              <b-dropdown-item
                               @click="showLog(data.item)"
              >
                <feather-icon icon="FileTextIcon"/>
                <span class="align-middle ml-50">成本调整日志</span>
              </b-dropdown-item>

            </b-dropdown>
          </template>

        </b-table>
    </b-card>
    <b-modal
        id="modal-refund"
        centered
        size="lg"
        hide-footer
        title="退款申请"
        ref="refundModal"
    >
      <purchase-refund-edit :order-item="curRow" @closeModal = "closeModalRefund"></purchase-refund-edit>
    </b-modal>
    <b-modal
        id="modal-log"
        hide-footer
        centered
        size="xl"
        title="成本调整日志"
        ref="logModal"
    >
      <purchase-order-item-cost-log :item_id="item_id"  ></purchase-order-item-cost-log>
    </b-modal>

  </div>

</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BFormCheckbox, BModal,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted, reactive, toRefs } from '@vue/composition-api'
import mainTableItemUseList from './mainTableItemUseList'
import purchaseorderitemStore from './purchaseorderitemStore'
import purchaseOrderItemCostLog from './PurchaseOrderItemCostLog'
import PurchaseRefundEdit from '@/views/apps/purchaserefund/PurchaseRefundEdit'
import {getCodeLabel} from "@core/utils/filter";
export default {
  components: {
    purchaseOrderItemCostLog,
    PurchaseRefundEdit,
    BModal,
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },

  props: {
    order_id: {
      type: Number,
      default: 0
    },
    isWatcher:{
      type:Boolean,
      default: false
    },
  },
  setup(props) {
    // Register module
    if (!store.hasModule('purchaseorderitem')) store.registerModule('purchaseorderitem', purchaseorderitemStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('purchaseorderitem')) store.unregisterModule('purchaseorderitem')
    })

    const itemList = mainTableItemUseList({
      order_id: props.order_id,
      isWatcher: props.isWatcher,
    })


    const state = reactive({
      curRow:undefined,
      refundModal:undefined,
      logModal:undefined,
      item_id:undefined,
    })

    const methods = {
      refund(row){
        //深拷贝
        state.curRow = { ...row }
        state.refundModal.show()
      },
      showLog(row){
        state.item_id = row.item_id
        state.logModal.show()
      },
      closeModalRefund(){
        state.refundModal.hide()
      }
    }
    return {
      ...toRefs(state),
      ...itemList,
      ...methods,
      getCodeLabel,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.delLine{
  z-index: 999;
  position: absolute;

  height: 1px;
  line-height: 1px;
  border-top: 1px solid orange !important;
  transform: rotate(0.0deg); /*倾斜度*/
  display: none;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
