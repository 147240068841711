import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { getCodeRemark } from '@core/utils/filter'

export default function purchaseorderUseList() {
  // Use toast
  const toast = useToast()

  const refListTable = ref(null)

  const tableColumns = [
    { key: 'order_no', label: '编号', sortable: true },
    { key: 'supplier_id', label: '供应商', sortable: true ,formatter:(value, key, item)=>(getCodeRemark("company",value) || item.supplier_name)},
    { key: 'company_id', label: '签约主体', sortable: true ,formatter:(value, key, item)=>(getCodeRemark("company",value) || item.company_name)},
    { key: 'purchase_total', label: '预计金额', sortable: true },
    { key: 'total_paid', label: '已付金额', sortable: true },
    { key: 'invoice',headerTitle:'资金敞口=已付金额-已入库总金额+总退款金额', label: '资金敞口', formatter:(value,key,item) =>  (item.total_paid||0) - (item.total_pay||0) + (item.refund_amount||0 )  },
    { key: 'invoice_amount', label: '发票金额', sortable: true },
    { key: 'total_pay', label: '已入库总金额', sortable: true },
    // { key: 'total_qty', label: '采购数量', sortable: true },
    // { key: 'total_delivery_qty', label: '已入库数量', sortable: true },
    { key: 'refund_amount', label: '总退款金额',sortable: true},
    // { key: 'refund_qty', label: '总退款数量',sortable: true},
    // { key: 'remark', label: '备注', sortable: true },
    // { key: 'applier_name', label: '申请人', sortable: true },
    { key: 'status', label: '订单状态', sortable: true },
  ]

  const start = ref(1)
  const limit = ref(10)
  const limitOptions = [10, 25, 50, 100]
  const listTotals = ref(0)
  const orderBy = ref('order_id')
  const isSortDirDesc = ref(true)
  const searchKey = ref("")

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: limit.value * (start.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start.value - 1) + localItemsCount,
      of: listTotals.value,
    }
  })

  const refetchData = () => {
    refListTable.value.refresh()
  }

  watch([start, limit,searchKey], () => {
    refetchData()
  })

  const searchList = (ctx, callback) => {
    store
      .dispatch('purchaseorder/select', {
        start: start.value,
        limit: limit.value,
        searchKey:searchKey.value,
        order_by: orderBy.value,
        order_desc: isSortDirDesc.value === true ? 'desc':'',
      })
        .then(response => {
          const data = response.data.data
          const list = data.ext.list
          listTotals.value = data.ext.totals
          callback(list)
        })
        .catch((e) => {
          toast({
            component: ToastificationContent,
            props: {
              title: '采购订单列表获取错误',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
  }

  return {
    searchKey,
    searchList,
    tableColumns,
    limit,
    start,
    listTotals: listTotals,
    dataMeta,
    limitOptions,
    orderBy,
    isSortDirDesc,
    refListTable,

    refetchData,

  }
}
