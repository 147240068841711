<template>

  <div>

    <!-- Table Container Card -->
    <b-card
    >
      <b-table
          ref="refListTable"
          class="position-relative"
          :items="list"
          responsive
          hover
          :fields="tableColumns"
          show-empty
          empty-text="未找到记录"
      >
        <template #cell(order_no)="data">
          {{data.item.order_no}}
        </template>
        <template #cell(name)="data">
          {{data.item.name}}
        </template>
        <template #cell(product_cost_tax_old)="data">
          {{data.item.product_cost_tax_old}}
        </template>
        <template #cell(change_cost)="data">
          {{data.item.change_cost}}
        </template>
        <template #cell(product_cost_tax_new)="data">
          {{data.item.product_cost_tax_new}}
        </template>
        <template #cell(change_type)="data">
          {{getCodeLabel("cost_change_log_type",data.item.change_type)}}
        </template>
        <template #cell(remark)="data">
          {{data.item.remark}}
        </template>
        <template #cell(creator)="data">
          {{getCodeLabel("user",data.item.creator)}}
        </template>
        <template #cell(create_time)="data">
          {{toTime(data.item.create_time)}}
        </template>

      </b-table>

    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText, toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor } from '@core/utils/filter'
import purchaseorderitemcostStore from './purchaseorderitemcostStore'
import purchaseOrderItemCostLogList from './purchaseOrderItemCostLogList'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  props: ['item_id'],
  setup(props) {
    // Register module
    if (!store.hasModule('purchaseorderitemcostStore')) store.registerModule('purchaseorderitemcostStore', purchaseorderitemcostStore)


    const {
      tableColumns,
      list,

      // UI
    } = purchaseOrderItemCostLogList({},props)

    return {
      tableColumns,
      list,

      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      toTime,
      toDate,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
