<template>

  <div>

    <!-- Table Container Card -->
    <b-card
        no-body
        class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <b-col
              cols="12"
              md="6"
          >
            <xy-button
                variant="primary"
                @click="handleAdd"
            >
              <span class="text-nowrap">增加</span>
            </xy-button>
          </b-col>
          <b-col md="3">
          </b-col>
<!--          <b-col md="3">
            <b-alert
                variant="primary"
                show
            >
              <div class="alert-body">
                <span>折后金额=应付金额-现金折扣 || 实付金额=折后金额+手续费</span>
              </div>
            </b-alert>
          </b-col>-->
        </b-row>

      </div>
      <ValidationObserver ref="observer">
        <b-table
            ref="refListTable"
            class="position-relative"
            :items="filterItems"
            responsive
            hover
            :fields="tableColumns"
            show-empty
            empty-text="未找到记录"
            :busy="isBusy"
            @row-hovered="onRowHovered"
        >
          <template #row-details="{item}">
            <b-card>
              <b-row>
                <b-col v-for="(value,name,index) in editableData[item.id].detailConfig" :key="index" md="4">
                  <xy-form  :prop-data="value" v-model="editableData[item.id][name]"></xy-form>
                </b-col>
              </b-row>
            </b-card>

          </template>

          <template #cell(#)="row">
            <feather-icon
                :icon= "row.detailsShowing ? 'MinusSquareIcon' : 'PlusSquareIcon'"
                @click="toggleDetail(row)"
                class="mr-1"
            />
            <span>{{ row.index + 1 }}</span>
          </template>

          <template #cell(f_settlement_method)="{field,value,item}">
            <div style="width: 140px" v-if="editable(item.id)">
              <xy-select :vid="`${field.key}_${item.id}`" rules="required" :val.sync="editableData[item.id][field.key]"
                         options-type="f_settlement_method"
                         name="结算方式"
              ></xy-select>
            </div>
            <div v-else>{{ getCodeLabel('f_settlement_method', value) }}</div>
          </template>

          <template #cell(payment_purpose)="{field,value,item}">
            <div style="width: 140px" v-if="editable(item.id)">
              <xy-select :vid="`${field.key}_${item.id}`" rules="required" :val.sync="editableData[item.id][field.key]"
                         options-type="f_payment_purpose"
                         name="付款用途"
              ></xy-select>
            </div>
            <div v-else>{{ getCodeLabel('f_payment_purpose', value) }}</div>
          </template>

          <template v-for="col in inputCol" v-slot:[col.key]="{field,value,item}">
            <div>
              <xy-input :vid="`${field.key}_${item.id}`" :rules="col.rules" :val.sync="editableData[item.id][field.key]"
                        v-if="editable(item.id)" :name="field.label" v-on="col.on" :readonly="col.readonly"
              ></xy-input>
              <div v-else>{{ value }}</div>
            </div>
          </template>


          <!-- Column: Actions -->
          <template #cell(actions)="row">
            <span v-if="editable(row.item.id)">
              <xy-link @click="save" class="mr-1">保存</xy-link>
<!--              <b-link @click="cancel">取消</b-link>-->
              <xy-pop-confirm  title="确认取消？" @confirm="cancel">
                <xy-link >取消</xy-link>
              </xy-pop-confirm>
            </span>
            <span v-else>
              <xy-link @click="edit" class="mr-1">编辑</xy-link>
               <xy-pop-confirm  title="确认删除？" @confirm="del">
                <xy-link>删除</xy-link>
              </xy-pop-confirm>
            </span>
          </template>

        </b-table>
      </ValidationObserver>

      <b-modal
          id="modal-order"
          centered
          size="xl"
          hide-footer
          title="请选择采购订单"
          ref="orderModal"
      >

        <purchase-order-select @onRowDblClicked="onRowDblClicked" ref="orderSelect"  >

        </purchase-order-select>
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,BPopover
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted, toRefs, computed, watch, reactive, inject } from '@vue/composition-api'
import { getCodeOptions, getCode, getCodeLabel, getCodeColor } from '@core/utils/filter'
import { toTime, toDate } from '@core/utils/dayjs/utils'
import financepaymentitemUseList from './financepaymentitemUseList'
import financepaymentitemStore from './financepaymentitemStore'
import XyInput from '@/views/components/xy/XyInput'
import XySelect from '@/views/components/xy/XySelect'
import CodeSelect from '@/views/apps/code/CodeSelect'
import XyForm from '@/views/components/xy/XyForm'
import XyPopConfirm from '@/views/components/xy/XyPopConfirm'
import XyButton from '@/views/components/xy/XyButton'
import XyLink from '@/views/components/xy/XyLink'
import PurchaseOrderSelect from '@/views/apps/purchaseorder/select/PurchaseOrderSelect'

export default {
  components: {
    PurchaseOrderSelect,
    XyLink,
    XyButton,
    XyPopConfirm,
    BPopover,
    XyForm,
    CodeSelect,
    XySelect,
    XyInput,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  props: ['f_payment_id'],
  setup(props, { emit }) {
    // Register module
    if (!store.hasModule('financepaymentitem')) store.registerModule('financepaymentitem', financepaymentitemStore)

    // UnRegister on leave
    /* onUnmounted(() => {
       if (store.hasModule('financepaymentitem')) store.unregisterModule('financepaymentitem')
     })*/

    const {f_payment_id} = toRefs(props)

    const {
      methods: useListMethods,
      state: userListState
    } = financepaymentitemUseList({
      f_payment_id: f_payment_id.value
    }, emit)


    return {
      ...toRefs(userListState),
      ...useListMethods,

      // Filter
      getCodeOptions,
      getCodeLabel,
      getCodeColor,

      toTime,
      toDate,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

