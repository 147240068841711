<template>

  <div>

    <!-- Table Container Card -->
    <b-card
        no-body
        class="mb-0 list-table"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
                v-model="limit"
                :options="limitOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
          </b-col>

          <!-- Search -->
          <b-col
              cols="12"
              md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                  v-model="searchKey"
                  class="d-inline-block mr-1"
                  placeholder="搜索关键字..."
              />
              <!--
              <b-button
                  variant="primary"
                  :to="{ name: 'apps-product-edit'}"
              >
                <span class="text-nowrap">添加</span>
              </b-button>
              -->
            </div>
          </b-col>

        </b-row>

      </div>

      <b-table
          ref="refListTable"
          class="position-relative"
          :items="searchList"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="orderBy"
          show-empty
          empty-text="未找到记录"
          :sort-desc.sync="isSortDirDesc"
          select-mode="single"
          selectable
          @row-dblclicked="onRowDblClicked"
      >

        <template #cell(order_no)="data">
<!--          <b-link
              :to="relationTo(data.value)"
              target="_blank"
          >
            {{ data.value }}
          </b-link>-->
          {{ data.value }}
        </template>

<!--        <template #cell(inbound)="data">
          <feather-icon
              icon= "CpuIcon"
              :badge="data.item.ext['inbound_times']"
              badge-classes="badge-danger"
              @click="showInboundModal(data.item)"
              size="21"
          />
        </template>-->


<!--        <template #cell(supplier_id)="data">
          <div :id="`supplier_id-row-${data.item.id}`">
            {{ data.value.substring(0, 3)}}
            <span v-if="data.value.length > 3"> ...</span>
          </div>

          <b-tooltip
              :target="`supplier_id-row-${data.item.id}`"
              placement="top"
              v-if="data.value"
          >
            {{ data.value }}
          </b-tooltip>
        </template>


        <template #cell(company_id)="data">
          <div :id="`company_id-row-${data.item.id}`">
            {{ data.value.substring(0, 3)}}
            <span v-if="data.value.length > 3"> ...</span>
          </div>

          <b-tooltip
              :target="`company_id-row-${data.item.id}`"
              placement="top"
              v-if="data.value"
          >
            {{ data.value }}
          </b-tooltip>
        </template>-->

        <template #cell(total_pay)="data">
          <div :id="`total_pay-row-${data.item.id}`">
            {{ data.value || 0 }}
          </div>
<!--          <b-tooltip
              :target="`total_pay-row-${data.item.id}`"
              placement="top"
          >
            已入库数量： {{ data.item.total_delivery_qty || 0 }}
            <br>
            已入库总金额： {{ data.value || 0 }}
          </b-tooltip>-->

        </template>

        <template #cell(purchase_total)="data">
          <div :id="`purchase_total-row-${data.item.id}`">
            {{ data.value || 0 }}
          </div>

<!--          <b-tooltip
              :target="`purchase_total-row-${data.item.id}`"
              placement="top"
          >
            采购数量： {{ data.item.total_qty || 0 }}
            <br>
            预计金额： {{ data.value || 0 }}
          </b-tooltip>-->
        </template>

        <template #cell(refund_amount)="data">
          <div :id="`refund_amount-row-${data.item.id}`">
            {{ data.value || 0 }}
          </div>

<!--          <b-tooltip
              :target="`refund_amount-row-${data.item.id}`"
              placement="top"
          >
            总退款数量： {{ data.item.refund_qty || 0 }}
            <br>
            总退款金额： {{ data.value || 0 }}
          </b-tooltip>-->
        </template>

        <template #cell(refund_qty)="data">
          {{ data.value || 0 }}
        </template>

        <template #cell(total_qty)="data">
          {{ data.item.total_qty || 0 }}
        </template>

        <template #cell(total_delivery_qty)="data">
          {{ data.item.total_delivery_qty || 0 }}
        </template>

        <template #cell(type_id)="data">
          {{ getCodeLabel('business_type', data.item.type_id) }}
        </template>

        <!-- 备注 -->
        <template #cell(remark)="data">
          <div :id="`remark-row-${data.item.id}`">
            {{  data.value.substring(0, 3) }}
            <span v-if="data.value.length > 3"> ...</span>
          </div>
          <b-tooltip
              :target="`remark-row-${data.item.id}`"
              placement="top"
              v-if="data.value"
          >
            {{ data.value }}
          </b-tooltip>
        </template>

        <!-- Column: status -->
        <template #cell(status)="data">
          <b-badge
              pill
              :variant="`light-${getCodeColor('contract_status',data.item.status)}`"
          >
            {{ getCodeLabel('contract_status', data.value) }}
          </b-badge>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
                v-model="start"
                :total-rows="listTotals"
                :per-page="limit"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import store from '@/store'
import { onUnmounted, reactive, toRefs } from '@vue/composition-api'
import { getCodeColor, getCodeLabel } from '@core/utils/filter'
import purchaseorderUseSelect from './purchaseorderUseSelect'
import purchaseorderStore from '../purchaseorderStore'
import { useToast } from 'vue-toastification/composition'
import MainTableItemList from '@/views/apps/purchaseorderitem/MainTableItemList'

export default {
  components: {
    MainTableItemList,
  },

  setup(props,{emit}) {
    const toast = useToast()
    // Register module
    if (!store.hasModule('purchaseorder')) store.registerModule('purchaseorder', purchaseorderStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('purchaseorder')) store.unregisterModule('purchaseorder')

    })

    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      searchKey,
      // UI
    } = purchaseorderUseSelect()


    const state = reactive({
      curRow:{},

    })

    const methods = {
      relationTo(value){
        const condition = {
          order_no:value
        }
        store.commit('purchaseorder/updateCondition', condition)
        console.log(value)
        return { name: 'apps-purchaseorder-list' }
      },
      onRowDblClicked(item){
        emit("onRowDblClicked",item)
      }
    }

    return {
      ...toRefs(state),
      ...methods,

      searchKey,
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      getCodeLabel,
      getCodeColor,
    }
  }
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
