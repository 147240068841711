import { ref, watch, computed, onMounted } from '@vue/composition-api'
import store from '@/store'

export default function purchaseOrderItemCostLogList(config,props) {

  const tableColumns = [
    { key: 'order_no', label: '采购订单编号'},
    { key: 'name', label: '名称'},
    { key: 'product_cost_tax_old', label: '修改前含税单价'},
    { key: 'change_cost', label: '本次调整金额'},
    { key: 'product_cost_tax_new', label: '修改后含税单价'},
    { key: 'change_type', label: '调整类型'},
    { key: 'remark', label: '备注'},
    { key: 'creator', label: '创建人'},
    { key: 'create_time', label: '创建日期'},

  ]

  const list = ref([])
  const searchList = async () => {
    const res = await store
      .dispatch('purchaseorderitemcostStore/search', {
        item_id:props.item_id
      })
    const data = res.data.data
    list.value = data.list
  }
  onMounted( async ()=>{
    await searchList()
  })

  return {
    tableColumns,
    list,
  }
}
